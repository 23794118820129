html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.logo-size{ 
  width: 160px;  
}

.logoHeader{ 
  display: flex;
  height: 85px;  
  align-items: center;
}

.logo{ 
  width: 160px;  
}

.nav {  
  display: flex;
  justify-content: end;
}

.logoFooter {
  max-width: 350px;
  width: 100%;
}

.social-media-widget p {
  font-size: 1.1rem;
}

.social-links {
  display: flex;  
  justify-content: space-evenly;
  margin-top: 30px;  ;  
}

.social-links i {
  font-size: 35px;
}

.gMap {
  width: 100%;
  height: 100%;
}

.formContainer {
  position: absolute;  
  top: -50%;    
  right: -15%;
}

.contact-section {
  position: relative;
}

.stickyBanner {
  background-color: #15191f;
  height: 30px;    
  display: flex;
  justify-content: center;
  align-items: center;    
  gap: 50px;
  position: fixed;
  bottom: 0;
  width: 100vw;
}

.stickyBanner h5 {
  color: white;
}

.stickyBanner a {
  text-decoration: none;
  color: white;
}

.stickyBanner a:hover {  
  color: #ff8e31;
}


.stickyBanner i {
  color: #ff8e31;
  padding: 0 10px 0 10px;  
}

.errors {
  color: red;
  font-size: 0.8rem;
  margin-top: -25px;
}

@media screen and (max-width: 1600px) {
  .contact-form {
    visibility: hidden;
  }  
}

@media screen and (max-width: 991px) {
  .logo-size {
    width: 5rem;
  }
}

@media screen and (max-width: 650px) {
  .centerLogo {
    display: flex;
    justify-content: center;
    align-items: center;    
  }
  .stickyBanner  {    
    flex-direction: column;    
    height: 60px;            
      gap: 10px;
  }
  .stickyBanner h5 {
    margin: 0;    
  }

  .social-media-widget p {
    font-size: 0.9rem;
  }

  .social-links i {        
    margin-bottom: 20px;    
    font-size: 25px;
    
  }  
}

.toasty {
  width: 300px;

}

